import React from "react";
import Javascript from "../Images/javascript.jpg";
import Typescript from "../Images/typescript.png";
import ReactI from "../Images/react.png";
import Gatsby from "../Images/gatsby.png";
import Redux from "../Images/redux.png";
import Mobx from "../Images/mobx.png";
import Firebasee from "../Images/firebase.webp";
import Aws from "../Images/aws.png";
import GCP from "../Images/gcp.png";
import Ethereum from "../Images/ethereum.png";
import Mongodb from "../Images/mongodb.png";
import Sql from "../Images/sql.jpg";
import Postgres from "../Images/postgres.png";
import Nodejs from "../Images/nodejs.png";
import Next from "../Images/nextjs.png";
import Vue from "../Images/vuejs.png";
import Svelte from "../Images/svelte.png";
import Angular from "../Images/angular.svg";
import Saas from "../Images/saas.png";
import Storybook from "../Images/storybook.svg";
import "../Css/Techstack.css";

const Techstack = () => {
  return (
    <>
      <div className="tech-container">
        <div className="single-tech">
          <img src={Javascript} alt="js" className="tech-img" />
          <p className="name">Javascript</p>
        </div>
        <div className="single-tech">
          <img src={Typescript} alt="typescript" className="tech-img" />
          <p className="name">TypeScript</p>
        </div>
        <div className="single-tech">
          <img src={ReactI} alt="react" className="tech-img" />
          <p className="name">React</p>
        </div>
        <div className="single-tech">
          <img src={Gatsby} alt="gatsby" className="tech-img" />
          <p className="name">Gatsby</p>
        </div>
        <div className="single-tech">
          <img src={Redux} alt="redux" className="tech-img" />
          <p className="name">Redux</p>
        </div>
        <div className="single-tech">
          <img src={Mobx} alt="mobx" className="tech-img" />
          <p className="name">MobX</p>
        </div>
        <div className="single-tech">
          <img src={Firebasee} alt="firebase" className="tech-img" />
          <p className="name">Firebase</p>
        </div>
        <div className="single-tech">
          <img src={Aws} alt="aws" className="tech-img" />
          <p className="name">AWS</p>
        </div>
        <div className="single-tech">
          <img src={GCP} alt="gcp" className="tech-img" />
          <p className="name">Google Cloud</p>
        </div>

        <div className="single-tech">
          <img src={Ethereum} alt="ethereum" className="tech-img" />
          <p className="name">Ethereum</p>
        </div>
        <div className="single-tech">
          <img src={Mongodb} alt="mongodb" className="tech-img" />
          <p className="name">MongoDB</p>
        </div>
        <div className="single-tech">
          <img src={Sql} alt="sql" className="tech-img" />
          <p className="name">SQL</p>
        </div>
        <div className="single-tech">
          <img src={Postgres} alt="postgres" className="tech-img" />
          <p className="name">PostgreSQL</p>
        </div>
        <div className="single-tech">
          <img src={Nodejs} alt="node-js" className="tech-img" />
          <p className="name">Node.js</p>
        </div>
        <div className="single-tech">
          <img src={Next} alt="nextjs" className="tech-img" />
          <p className="name">Next.JS</p>
        </div>
        <div className="single-tech">
          <img src={Vue} alt="vuejs" className="tech-img" />
          <p className="name">Vue.js</p>
        </div>
        <div className="single-tech">
          <img src={Svelte} alt="svelte" className="tech-img" />
          <p className="name">Svelte</p>
        </div>
        <div className="single-tech">
          <img src={Angular} alt="angular" className="tech-img" />
          <p className="name">Angular</p>
        </div>
        <div className="single-tech">
          <img src={Saas} alt="saas" className="tech-img" />
          <p className="name">SAAS</p>
        </div>
        <div className="single-tech">
          <img src={Storybook} alt="storybook" className="tech-img" />
          <p className="name">Storybook</p>
        </div>
      </div>
    </>
  );
};

export default Techstack;
