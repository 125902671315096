import React from "react";
import "../Css/Logos.css";
import { useMediaQuery } from "@react-hook/media-query";
import Linkedin from "../Images/linkedin.svg";
import Intuit from "../Images/intuit.png";
import CE from "../Images/coding-elements.png";
import Trainerapp from "../Images/trainer-app.png";
import WS from "../Images/wikisheets.png";
import Bizconnect from "../Images/bizconnect.png";
import Facebook from "../Images/facebook.jpg";
import Filmio from "../Images/filmio.png";
import Medisports from "../Images/medisports.png";
import Nitiaayog from "../Images/niti-aayog.jpg";

const Logo = () => {
  const isHorizontal = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <div class="slide">
        <img
          src={Linkedin}
          height={isHorizontal ? "50" : "80"}
          width="200"
          alt="linkedin"
        />
      </div>
      <div class="slide">
        <img
          src={Intuit}
          height={isHorizontal ? "50" : "60"}
          width="200"
          alt="intuit"
        />
      </div>
      <div class="slide">
        <img
          src={CE}
          height={isHorizontal ? "50" : "80"}
          width={isHorizontal ? "90" : "120"}
          alt="coding-elements"
        />
      </div>
      <div class="slide">
        <img
          src={Trainerapp}
          height={isHorizontal ? "50" : "90"}
          width="160"
          alt="trainer-app"
        />
      </div>
      <div class="slide">
        <img
          src={WS}
          height={isHorizontal ? "50" : "80"}
          width={isHorizontal ? "95" : "120"}
          alt="wikisheets"
        />
      </div>
      <div class="slide">
        <img src={Bizconnect} width="200" alt="bizconnect" />
      </div>
      <div class="slide">
        <img
          src={Facebook}
          height={isHorizontal ? "50" : "80"}
          width="200"
          alt="facebook"
        />
      </div>
      <div class="slide">
        <img
          src={Filmio}
          height={isHorizontal ? "50" : "80"}
          width="200"
          alt="filmio"
        />
      </div>
      <div class="slide">
        <img
          src={Medisports}
          height={isHorizontal ? "50" : "80"}
          width="180"
          alt="medi-sports"
        />
      </div>
      <div class="slide">
        <img
          src={Nitiaayog}
          height={isHorizontal ? "50" : "80"}
          width="180"
          alt="nitiaayog"
        />
      </div>
    </>
  );
};

const Logos = () => {
  return (
    <>
      <div class="logo-slider">
        <div class="slide-track">
          <Logo />
          <Logo />
          <Logo />
        </div>
      </div>
    </>
  );
};

export default Logos;
