import React from 'react'
import Header from './Components/Header'
import Home from './Components/Home'
import './index.css'

function App() {
  return (
    <div className='App'>
      <Header />
      <Home />
    </div>
  )
}

export default App
